<template>
  <div class="cooperation g-safe-area">
    <div class="g-title">
      <img
        src="../../assets/img/arrow-left.svg"
        alt=""
        @click="$router.go(-1)"
      />
      <p>{{ t("business_cooperation") }}</p>
    </div>

    <div class="g-container">
      <el-form ref="form" :model="form" :rules="formRules">
        <h5 class="g-must g-h5">{{ t("describe") }}</h5>
        <el-form-item prop="des">
          <section class="g-section">
            <el-input
              type="textarea"
              :rows="2"
              :placeholder="t('please_enter_content')"
              autosize
              maxlength="500"
              show-word-limit
              resize="none"
              v-model="form.des"
            ></el-input>
          </section>
        </el-form-item>
        <sub class="g-sub">{{ t("cooperation_describe_sub") }}</sub>

        <h5 class="g-must g-h5 g-top64">{{ t("email") }}</h5>
        <el-form-item prop="email">
          <section class="g-section">
            <el-input
              v-model="form.email"
              :placeholder="t('please_enter_email')"
            ></el-input>
          </section>
        </el-form-item>
        <sub class="g-sub">{{ t("email_sub") }}</sub>
      </el-form>
    </div>
    <el-button type="primary" style="margin-top: 32px" @click="submit">{{
      t("submit")
    }}</el-button>
  </div>
</template>

<script lang="ts">
import { emailValidator } from "@/utils/validators";
import { submitFeedback } from "@/http";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "cooperation",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      form: {
        des: "",
        email: "",
      },
      formRules: {
        des: {
          required: true,
          message: this.t("please_enter_content"),
          trigger: "change",
        },
        email: {
          validator: emailValidator,
          trigger: "change",
        },
      },
    };
  },
  methods: {
    async submit() {
      // eslint-disable-next-line
      const formRef: any = this.$refs.form;
      formRef.validate(async (valid: boolean) => {
        if (valid) {
          await submitFeedback({
            category: "cooperation",
            content: this.form.des,
            contact_info: this.form.email,
          });
        } else {
          return false;
        }
      });
      // todo toast
    },
  },
});
</script>

<style scoped lang="scss"></style>
