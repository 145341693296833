
import { emailValidator } from "@/utils/validators";
import { submitFeedback } from "@/http";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "cooperation",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      form: {
        des: "",
        email: "",
      },
      formRules: {
        des: {
          required: true,
          message: this.t("please_enter_content"),
          trigger: "change",
        },
        email: {
          validator: emailValidator,
          trigger: "change",
        },
      },
    };
  },
  methods: {
    async submit() {
      // eslint-disable-next-line
      const formRef: any = this.$refs.form;
      formRef.validate(async (valid: boolean) => {
        if (valid) {
          await submitFeedback({
            category: "cooperation",
            content: this.form.des,
            contact_info: this.form.email,
          });
        } else {
          return false;
        }
      });
      // todo toast
    },
  },
});
